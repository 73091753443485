<template>
    <div class="rawMateriaPurchase">
        <div class="rawMateriaPurchaseBox">
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>采购数量</h1>
                        <span @click="lookMore(1)">查看完整数据</span>
                    </div>
                    <div class="chsrtBox" id="contractQuantityId"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>采购金额</h1>
                        <span @click="lookMore(2)">查看完整数据</span>
                    </div>
                    <div class="chsrtBox" id="estimatedSalesId"></div>
                </div>
            </div>
            <!-- 导出excel -->
            <exportDom :dom-id="'.el-table__fixed'" :excel-name="'原材料采购统计'"></exportDom>
            <div class="table-list">
                <el-table
                    id="statisticsDom"
                    :data="tabListDataName"
                    style="width: 100%"
                    max-height="330"
                    ref="table"
                    show-summary
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        prop="name"
                        label="公司名称"
                        width="150"
                        fixed
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                        width="140"
                    >
                        <el-table-column
                            label="采购数量(吨)"
                            width="140"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.cgsl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="采购费用(万元)"
                            width="140"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.cgfy }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
        <classifiedDrilling v-if="showClassification" :click-name="clickName" @closeChildAlert="closeChildFn"></classifiedDrilling>
    </div>
</template>

<script>
// import addBgImages from '../utils';
import exportDom from './../salesStatistics/components/export';
import alertEcharts from './components/rawMateriaPurchase/vieFullData';
import classifiedDrilling from './components/rawMateriaPurchase/classifiedDrilling';
export default {
    data() {
        return {
            showContract: false,
            showClassification: false,
            clickName: '',
            alertStatus: 0,
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
        };
    },
    components: {
        alertEcharts,
        classifiedDrilling,
        exportDom,
    },
    mounted() {
        this.$nextTick(async () => {
            // 原材料采购统计
            await this.salescontractMonths();
            await this.$refs.table.doLayout();
        });
    },
    methods: {
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量和预计采购数量的数值数组 // 对应每一列的数据数组
                // eslint-disable-next-line consistent-return
                const values = arr.map(item => {
                    if (column.label === '采购数量(吨)') {return item.cgsl;}
                    if (column.label === '采购费用(万元)') {return item.cgfy;}
                });
                // const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return (Number(prev) + Number(curr)).toFixed(2);
                        }
                        return Number(prev).toFixed(2);

                    }, 0);
                } else {
                    sums[index] = '0';
                }
            });
            return sums;
        },
        closeChildFn() {
            this.showContract = false;
            this.showClassification = false;
        },
        // 查看详情
        lookMore(status) {
            // status 7:采购数量  8:采购金额
            this.alertStatus = status;
            this.showContract = true;
        },
        // 采购数量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [0, 0, 0, 0],
                },
                grid: {
                    top: '80',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(吨)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(吨)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showClassification = true;
                } else {
                    that.clickName = params.name;
                    that.showClassification = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 采购金额
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [0, 0, 0, 0],
                },
                grid: {
                    top: '80',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        triggerEvent: true,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(万元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(万元)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showClassification = true;
                } else {
                    that.clickName = params.name;
                    that.showClassification = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 原材料采购统计
        async salescontractMonths() {
            await this.$axios
                .get('/interfaceApi/report/materialanalysis/yclcg/cz/months?date=&datenum=5')
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return {
                            name: item,
                            list: arr,
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 采购数量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.cgsl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 采购金额
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.cgfy;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 采购数量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = (Number(total) + Number(a.cgsl)).toFixed(2);
                        });
                        return Number(total);
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 采购金额合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = (Number(total) + Number(a.cgfy)).toFixed(2);
                        });
                        return Number(total);
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    // 采购数量图表
                    this.contractQuantityCh(htCompleteData);
                    // 采购金额图表
                    this.estimatedSalesCh(flCompleteData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.rawMateriaPurchase
    height 100%
.rawMateriaPurchaseBox
    height 100%
    display flex
    padding .14rem
    display flex
    flex-direction column
    background #fff
    .el-table__fixed-right thead tr th
        &:last-child
            background #F5F2ED !important
            color #823802 !important
        &:nth-last-child(2)
            background #F5F2ED !important
            color #823802 !important
         &:nth-last-child(3)
            background #F5F2ED !important
            color #823802 !important
    .el-table__fixed-body-wrapper tbody tr td
        &:last-child
            color #C9661E !important
        &:nth-last-child(2)
            color #C9661E !important
        &:nth-last-child(3)
            color #C9661E !important
    .el-table__fixed-footer-wrapper table tbody tr
        td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
                font-size .16rem
    .el-table__footer-wrapper table tbody td
        background none
        color #C9661E
    .top-echarts-two
        width 100%
        height 50%
        display flex
        .echarts-total
            padding .14rem
            border 1px solid #D7D7D7
            flex 1
            .title-box
                display flex
                justify-content space-between
                margin-bottom .14rem
                h1
                    color #333333
                    font-size .18rem
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                span
                    color #1577D2
                    height .25rem
                    padding 0 .2rem;
                    font-size .14rem
                    cursor pointer
                    border 1px solid #1577D2
            .chsrtBox
                width 100%
                height calc(100% - 0.4rem)
        .echarts-left
            margin-right .2rem
    .table-list
        margin-top .1rem
        width 100%
        height calc(50% - 0.5rem)
        .el-table
            height 100%
            .el-table__header
                border-collapse: collapse;
            thead tr th
                color #022782
                font-size .16rem
                text-align center
                padding 0
            tbody tr td
                height .4rem
                text-align center
                padding 0
                font-size .14rem
</style>